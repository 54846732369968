@import './../../../../styles/colors';

.tree {
  &_draggingSource {
    opacity: 0.3;
  }

  &_dropTarget {
    background-color: #e8f0fe;
  }
  padding-left: 10px;
}

ul {
  padding-left: 20px;
  list-style-type: none;
}
