@import './../../../../../styles/colors';

.root {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  height: auto;
  padding-inline-end: 8px;
  margin-bottom: 5px;
}

.expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.inputWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  &_actions {
    display: flex;
    align-items: center;
  }
}
