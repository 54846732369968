@import '../../styles/colors';
.activitiesSummary {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_button {
      justify-self: end;
    }
  }
  &_selectors {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  &_active {
    cursor: pointer;
  }
  .hasImage {
    color: $color-complementary-4;
  }
  &_fathersWorkpackage {
    width: 100%;
    align-items: left;
    &_current {
      color: black;
    }
    &_father {
      color: gray;
      font-size: 9px;
    }
  }
  &_totalHours {
    width: 100%;
    height: 20px;
    padding: 20px;
    font-size: 20px;
    font-family: bold;
  }
}
