.activities {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_button {
      justify-self: end;
    }
  }
  &_event {
    &_1 {
      background-color: lightblue;
    }
  }
  &_paper {
    min-height: 500px;
    padding: 15px;
    width: 100%;

    &_closeday {
      display: grid;
      cursor: pointer;
      justify-content: flex-end;
      padding-bottom: 20px;
    }
  }
  &_select {
    padding: 15px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 300px;
  }
}
