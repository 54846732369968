@import '../../styles/colors';

.projects {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_button {
      justify-self: end;
    }
  }

  &_color-cell {
    padding: 3px;
    background: white;
    border: 2px solid $color-light;
    border-radius: 5px;
    display: inline-block, ¡;
    &_color {
      width: 32px;
      height: 20px;
      border-radius: 2px;
      border: 1px solid #ccc;
    }
  }
}
