@import '../../styles/colors';
.clients {
	&_header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		&_button {
			justify-self: end;
		}
	}

	&_active {
		cursor: pointer;
	}
	.hasImage {
		color: $color-complementary-4;
	}
}
