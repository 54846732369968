.activityModal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  #MUI_title {
    grid-column: 1 / 4;
  }

  #MUI_remaining_effort {
    grid-column: 2 / 4;
  }
}
