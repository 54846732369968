$color-primary: #0b284c;
$color-secondary: #7f909f;
$color-complementary: #f37722;
$color-complementary-2: #ced6de;
$color-complementary-3: #687d95;
$color-complementary-4: #475b7a;
$color-light: #f0f0f0;
$color-white: #ffffff;
$color-dark: #28292b;
$color-medium: #adadad;
$color-normal: #707070;
$color-disabled: #e4e4e4;
$color-cancel: #adadad;
$color-delete: #fa6347;
$color-check: #3bbb3e;
$color-out: #fc0f03;
$color-in: #1015af;

//$color-primary: #193552;
