.usermodal {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, auto);
  grid-row-gap: 25px;
  //height: max-content;
  &_active {
    justify-self: end;
  }
  &_row {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
  }
  &_rolTitle {
    font-size: 12px !important;
    line-height: 12px !important;
  }
}
