@import '../../styles/colors';
.eventWrapper {
	display: grid;
	grid-template-rows: auto;
	padding: 1em;
	& > span {
		margin-bottom: 5px;
	}
	&_header{
		display: flex;
		display: flex;
		flex-direction: row;
		align-items: center;
		line-height: 1.5em;
		justify-content: space-between;
		margin-bottom: 5px;
		border-bottom: 1px solid white;
	}
	&_content{
		margin: 2px 0 4px 0;
		display: grid;
		gap: 4px;
		grid-template-columns: repeat(3,1fr);
		justify-items: left;
		&_label{
			grid-column: 1/4;
			justify-self: left;
		}
	}
}
