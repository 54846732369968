.efforts {
	&_header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		&_button {
			justify-self: end;
		}
	}
	&_selectors {
		display: grid;
		// width: 300px;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 10px;
	}
	&_active {
		cursor: pointer;
	}
}
