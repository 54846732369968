.workPagckagesModal {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
  &_rolTitle {
    font-size: 12px !important;
    line-height: 12px !important;
  }
  label {
    padding-bottom: 10px;
  }
}
