.effortsModal {
	display: grid;
	grid-template-columns: repeat(auto-fit, auto);
	grid-template-rows: repeat(auto-fit, auto);
	grid-gap: 10px;
	&_row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 25px;
		// &_select {
		// 	padding: 10px !important;
		// }
	}
	&_rolTitle {
		font-size: 12px !important;
		line-height: 12px !important;
	}
	// input {
	// 	padding: 10px;
	// }
	label {
		padding-bottom: 10px;
	}
}
