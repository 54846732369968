@import './../../../../../styles/colors';

.nodeDrag {
  background-color: $color-secondary;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25),
    0 0 0 1px rgba(0, 0, 0, 0.08);
  display: inline-grid;
  padding: 4px 8px;
  pointer-events: none;
  &_label {
    font-size: 12px;
    align-items: center;
    display: flex;
  }
}
