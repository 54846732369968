@import '../../styles/colors';
.workPackages {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_select {
      width: 300px;
    }
    &_button {
      justify-self: end;
    }
  }
}
