@import '../../styles/colors';
.liberateActivities {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_button {
      justify-self: end;
    }
  }
  &_selectors {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    margin-left: 10px;
  }
}
