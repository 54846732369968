@import '../../../../styles/colors';

.projectsModal {
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr) 2fr;
  grid-gap: 20px;
  align-items: flex-end;
  #MUI_name {
    grid-column: 2 / 6;
  }
  #MUI_client {
    grid-column: 1 / 2;
  }
  #MUI_description {
    grid-column: 2 / 6;
  }
  #MUI_planned_start_date {
    grid-column: 2 / 4;
  }
  #MUI_planned_end_date {
    grid-column: 4 / 6;
  }
  &_color {
    // display: flex;
    align-items: flex-end;
    &_label {
      color: $color-normal;
      font-size: 12px;
      padding: 0 0 2px 5px;
    }
  }
}
