.ActivityEffortsStyles {
  &_table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 10px;
    grid-gap: 20px;
    align-items: center;
    font-size: 13px;
    color: gray;
  }
  &_header {
    color: black;
    font-size: 20px;
  }
}
